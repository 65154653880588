import client from './axios';
import { ContentData } from './types';
import {
  CalendarInfoRes,
  CheckVisitorReq,
  CheckVisitorRes,
  ClassInInfoReq,
  ClassInInfoRes,
  GetVisitorDataRes,
  GMTDictRes,
  HomeDataRes,
  HowClasses,
  LessonListInfoReq,
  LessonListInfoRes,
  RecentlyCourseItem,
  StudentInfoRes,
  EmailResult,
  SubscriptionResult,
  GetMyCourseRes,
  GetLearnSituationInfoRes,
  ITrailDetailReq,
  ITrailCancelReq,
  ExpectationsGS2Res,
  GetMyBookingCourseRes,
  CalendarInfoV2Res,
} from './types/home';
import { LoginRes } from './types/login';
import { isTourist } from '@/utils/utils';
import { COMMON_API_HOST, WK_GATEWAY_URL as WK_GATEWAY } from '@/utils/env';
import { StatusEnum } from '@/api/types/curriculum';
import { AgreementItem } from '@/pages/agreement';
import { VerificationType } from '@/api/login';
import { AdvisorInfo } from './app';
import { getAPIBaseUrl } from '@/utils/url';

const WK_GATEWAY_URL = getAPIBaseUrl(WK_GATEWAY);

export const getStudentList = () => {
  const touristApi = 'public/tourist/account/students';
  return client
    .get<StudentInfoRes, StudentInfoRes>(isTourist() ? touristApi : 'api/acc/account/students')
    .then((res) => res?.content);
};

// 获取课表日历
export const getCalendarData = (year: number, month: number) => {
  return client
    .get<CalendarInfoRes, CalendarInfoRes>(`api/student/master/v2/schedule/year_month/${year}/${month}`)
    .then((res) => res?.content);
};

// 获取课表日历v2
export const getCalendarDataV2 = (year: number, month: number) => {
  return client
    .get<CalendarInfoRes, CalendarInfoV2Res>(`api/student/master/v2/schedule/year_month/v2/${year}/${month}`)
    .then((res) => res?.content);
};

// 获取排班课程
export const getLessonList = (data: LessonListInfoReq) => {
  return client
    .post<LessonListInfoRes, LessonListInfoRes>('api/student/master/v2/simple/schedule/date/v3', data)
    .then((res) => res?.content);
};

// deviceType: 1代表 Windows/Mac OS 端；2代表 iOS 移动端；3代表 Android
export const getClassInLink = (data: ClassInInfoReq) => {
  return client
    .post<ClassInInfoRes, ClassInInfoRes>('api/student/master/v2/login_link', data)
    .then((res) => res?.content);
};

interface GetTCUrlInterface {
  courseScheduleId: string;
  userType: 'TEACHER' | 'ASSISTANT' | 'STUDENT' | 'LIVE' | 'INSPECTOR' | 'AUDITOR' | 'PARENT' | 'AUDIENCE';
  clientType: 'WEB' | 'MAC' | 'IOS' | 'ANDROID';
  uuid: string;
  name: string;
  language: 'ZH_CN' | 'EN_US';
  trialStudent: boolean;
  norecord: boolean | null;
}

export const getTalkCloudLink = async (data: GetTCUrlInterface) => {
  return await client.get<string, string>(
    `${WK_GATEWAY_URL}/cp-scheduling/api/classroom/${data.courseScheduleId}/get-room-web-link?userId=${data.uuid}&userType=${data.userType}&language=${data.language}`,
  );
};

export const getGMTDict = () => {
  return client.get<GMTDictRes, GMTDictRes>('public/ref/timezones/list').then((res) => res?.content);
};

export const getHomeData = () => {
  return client
    .post<ContentData<HomeDataRes>, ContentData<HomeDataRes>>(
      `api/student/master/v2/homepage/v3`,
      {},
      {
        skipErrorHandler: true,
      },
    )
    .then((res) => res?.content);
};

// 是否可以进入本科重点
export const canViewClassData = (courseScheduleId: string) => {
  return client
    .get<
      ContentData<boolean>,
      ContentData<{ unlock: boolean; msg: string }>
    >(`api/student/schedule/teaching_materials/is_unlock/v2/${courseScheduleId}`)
    .then((res) => res?.content);
};

export const getCurTimezone = () => {
  return client
    .get<
      ContentData<{ offset: string; timezone: string; callingCode: string }>,
      ContentData<{ offset: string; timezone: string; callingCode: string }>
    >('public/open/ip_info_v2')
    .then((res) => res?.content);
};

export const getTimezones = () => {
  return client.get<ContentData<any>, ContentData<any>>('public/open/timezones/ddl').then((res) => res?.content);
};

export const getAccountInfo = () => {
  const touristApi = '/public/tourist/acc/account';
  return client.get<LoginRes, LoginRes>(isTourist() ? touristApi : '/api/acc/account').then((res) => res?.content);
};

export const checkShareAccess = () => {
  return client
    .get<ContentData<any>, ContentData<any>>('/api/student/sharing/v2/check/access')
    .then((res) => res?.content);
};

export const checkNotice = () => {
  return client
    .get<ContentData<boolean>, ContentData<boolean>>('public/open/classin/maintain')
    .then((res) => res?.content);
};

export const checkAccountSafe = () => {
  return client
    .post<ContentData<{ data: string[] }>, ContentData<{ data: string[] }>>('api/acc/account/complete_contact/popup')
    .then((res) => res?.content);
};

export const getVerifyCode = (type: 'EMAIL' | 'MOBILE') => {
  return client
    .post<
      ContentData<{ data: { contactValue: string } }>,
      ContentData<{ data: { contactValue: string } }>
    >(`api/acc/account/complete_contact/potential/${type}`)
    .then((res) => res?.content);
};

// 获取同步日历 ics
export const getCalendarIcs = () => {
  return client.get<ContentData<string>, ContentData<string>>('api/student/calendar/ics').then((res) => res?.content);
};

// 获取监课链接
// export const getMonitorLink = (stuCourseScheduleId: string) => {
//   return client
//     .get<ContentData<string>, ContentData<string>>(`api/student/schedule/monitor_link/talk/web/${stuCourseScheduleId}`)
//     .then((res) => res?.content);
// };

// 获取首页课程详情
export const getHomeCourseDetail = () => {
  return client.get<any, any>('api/student/master/v2/mobile_home_page?onlyStandard=true').then((res) => res?.content);
};

// Disassembled data in Home(api)
export const getRecentlyCourseData = (id: string) => {
  return client.get<Array<RecentlyCourseItem>, Array<RecentlyCourseItem>>(
    `${WK_GATEWAY_URL}/cp-scheduling/api/timetable/student/${id}/latest_day_schedule?onlyStandard=true`,
    {
      headers: {
        'X-SERVICE-NAME': 'cp-scheduling',
      },
    },
  );
};

export interface TeachingMaterialsRes {
  hasTeachingMaterials: boolean;
  homeworkDomainUrl: string;
  showCourseCommentButton: boolean;
  showPrePadHomePage: boolean;
  studyReportDomainUrl: string;
  uuid: string;
}

export interface TeachingMaterialsParams {
  uuid: string;
  courseScheduleId: string;
  courseSectionId: string;
  status: StatusEnum;
  startDateTime: string;
  timezone?: string;
  endDateTime: string;
  published: boolean;
}

export const getTeachingMaterialsInfo = (data?: Array<TeachingMaterialsParams>) => {
  return client.post<Array<TeachingMaterialsRes>, Array<TeachingMaterialsRes>>(
    `api/student/calendar/schedule/other-info`,
    data,
  );
};

export const getHowClassData = (data: { subject: HowClasses['subject']; platform: HowClasses['platform'] }) => {
  return client.post<{ content: HowClasses | null }, { content: HowClasses | null }>(
    isTourist() ? `public/tourist/class/intro/detail` : `/api/class/intro/detail`,
    data,
  );
};

// 获取学习规划师信息
export const getAcademicAdvisorInfo = (data?: { scanType: string }) => {
  return client
    .post<{ content: AdvisorInfo }, { content: AdvisorInfo }>('api/class/intro/show/planner_info', {
      ...data,
      subject: null,
    })
    .then((res) => res?.content);
};
// 获取whatsapp信息
export const getWhatsAppData = (data?: { scanType: string; subject: string; defaultInfo?: boolean }) => {
  return client
    .post<{ content: AdvisorInfo }, { content: AdvisorInfo }>('api/class/intro/show/planner_info', data)
    .then((res) => res?.content);
};

// 游客模式用户数据获取
export const getVisitorData = (data: { subject: string }) => {
  return client
    .post<GetVisitorDataRes, GetVisitorDataRes>('public/landing_page/home_page', data)
    .then((res) => res?.content);
};

// 游客验证
export const checkVisitor = (data: CheckVisitorReq) => {
  return client
    .post<CheckVisitorRes, CheckVisitorRes>('public/authentication/verify/send_code', data)
    .then((res) => res?.content);
};

// 否展示错题表
export const isShowQuestion = () => {
  return client.post<any, any>('api/homework/wrong_question_book/web/button/show').then((res) => res?.content);
};

// 错题接口
export const queryQuestionList = () => {
  return client.post<any, any>('api/homework/wrong_question_book/web/list').then((res) => res?.content);
};

// 订正错题ICON接口
export const isQuesionExit = () => {
  return client.post<any, any>('api/homework/wrong_question_book/icon/status').then((res) => res?.content);
};

// 获取当前最新协议/政策
export const getAgreementLatest = (agreementType: AgreementItem['agreementType']) => {
  return client.post<AgreementItem['agreementType'], AgreementItem>(
    `${WK_GATEWAY_URL}/ms-cms/public/agreement/${agreementType}/latest`,
  );
};
export interface AccountAssetsRes {
  cuDetailResp: {
    chineseRemainingCu: number;
    mathRemainingCu: number;
    englishRemainingCu: number;
  };
  englishName: string;
  fullName: string;
  refundCount: number;
  studentCode: string;
  studentId: string;
  subCount: number;
  headImage?: string;
}

export const GetAccountAssetsApi = () => {
  return client
    .get<
      { content: Array<AccountAssetsRes> },
      {
        content: Array<AccountAssetsRes>;
      }
    >('api/acc/assets')
    .then((res) => res.content);
};

export interface CheckVerifyCodeReq {
  verificationCode: string;
  target: string;
  verificationType: VerificationType;
}

export const CloseAccountApi = (data: CheckVerifyCodeReq) => {
  return client.post<{ content: boolean }, { content: boolean }>('api/acc/deactivate', data);
};

// 家长是否完成验证
// PENDING,//有记录未通过
// DONE,//验证通过
// FAILED,//兼容异常情况
// NONE; //未发送
export type VerifyType = 'PENDING' | 'DONE' | 'FAILED' | 'NONE';

export const getParentVerification = () => {
  return client
    .get<{ content: VerifyType }, { content: VerifyType }>('api/parentalVerification/status')
    .then((res) => res?.content);
};
// 家长验证接口
export const parentalVerification = (businessCode: string) => {
  return client
    .get<
      { content: EmailResult },
      { content: EmailResult }
    >(`public/open/parentalVerification?businessCode=${businessCode}`)
    .then((res) => res?.content);
};
// 家长验证发送邮件
export const sendParentalEmail = (email: string) => {
  return client.post(`api/parentalVerification/email/send`, { email });
};

// 家长验证发送邮件
export const getGradeList = (subject: string) => {
  return client
    .post<{ content: any }, { content: any }>(`public/open/grade/list`, { subject })
    .then((res) => res?.content);
};

// 获取脱敏邮件地址
export const queryEmail = () => {
  return client
    .get<{ content: string }, { content: string }>(`api/parentalVerification/getEmail`)
    .then((res) => res?.content);
};

// 获取邮件地址
export const getDecryptEmail = () => {
  return client
    .get<{ content: string }, { content: string }>(`api/parentalVerification/getDecryptEmail`)
    .then((res) => res?.content);
};

// 获取我的课程数据
export const getMyCourse = () => {
  return client.post<GetMyCourseRes, GetMyCourseRes>(`api/student/master/v2/course/my_course`, {});
};

// 获取自主订阅课程数据
export const getMyBookingCourse = () => {
  return client.get<GetMyBookingCourseRes, GetMyBookingCourseRes>(`api/student/master/v2/course/my_booking_course`);
};

export const getLearnSituationInfo = () => {
  return client.get<GetLearnSituationInfoRes, GetLearnSituationInfoRes>(
    `${COMMON_API_HOST}/api/public/studySituation/queryStudentStudyProgressIn3Month`,
  );
};

// 查询体验课详情
export const getTrialDetail = (params: ITrailDetailReq) => {
  return client.post<{ content: any }, { content: any }>(`api/reserve/trial_info`, params).then((res) => res?.content);
};
// 取消体验课
export const cancelTrialCourse = (params: ITrailCancelReq) => {
  return client.post<{ content: any }, { content: any }>(`api/student/schedule/cancelSchedule`, params);
};

// 获取当前用户体验课信息
//
export const getTrailList = () => {
  return client.get<{ content: any }, { content: any }>(`api/reserve/latest_trial`).then((res) => res?.content);
};

// 订阅3.o入口
export const getSubscriptionEsStatus = () => {
  return client
    .get<{ content: SubscriptionResult }, { content: SubscriptionResult }>(`api/student/master/v2/course/featured/V3`)
    .then((res) => res?.content);
};
// 获取Gs2.0 约课入口
export const getExpectationsGS2 = () => {
  return client.get<ExpectationsGS2Res, ExpectationsGS2Res>(`/api/reserve/gs_teacher_info`).then((res) => res?.content);
};
